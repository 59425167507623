(function($) {

$.attach('.block-open-table iframe', function(i, element) {

	element.on('detach', function() {
		element.remove()
	})

	element.attr('scrolling', 'no')

	var onResize = function() {

		try {

			// This somehow creates an error with firefox

			var contents = element.contents()
			if (contents) {
				element.css('height', contents.height())
				// element.css('width', contents.width())
			}

		} catch (e) {

		}
	}

	element.on('load', onResize);

	$(window).on('load', onResize)
	$(window).on('resize', onResize)

	onResize()
})

})(jQuery);