(function($) {
"use strict"

$.attach('.comment-form', function(i, element) {

	var clean = function(element) {

		var label = element.find('label')
		var input = element.find('input, textarea')

		label.remove()
		input.attr('placeholder', label.text())
	}

	var comment = element.find('.comment-form-comment')
	var author = element.find('.comment-form-author')
	var email = element.find('.comment-form-email')
	var submit = element.find('.form-submit')

	clean(comment)
	clean(author)
	clean(email)

	element.append(author)
	element.append(email)
	element.append(comment)
	element.append(submit)

})

})(jQuery);