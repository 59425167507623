(function($) {

$.attach('.awards', function(i, element) {

	var layout = function(media) {

		var container = element.find('.awards-layout-' + media)
		if (container.length === 0) {
			return
		}

		container.find('.award-cell').each(function(i, element) {

			element = $(element)

			var target = element.attr('data-target')
			if (target == '') {
				return
			}

			element.append($('.award[data-name="' + target + '"]'))
		})
	}

	$(window).on('mediaenter', function(e, media) {
		switch (media) {
			case 'xs':
				layout('xs')
				break
			case 'sm':
				layout('sm')
				break
			case 'md':
				layout('md')
				break
			case 'lg':
				layout('lg')
				break
		}
	})

	$(window).on('medialeave', function(e, media) {
		switch (media) {
			case 'xs':
				layout('xx')
				break
			case 'sm':
				layout('xs')
				break
			case 'md':
				layout('sm')
				break
			case 'lg':
				layout('md')
				break
		}
	})
})

})(jQuery);