;(function($) {
"use strict"

$.attach('.main-media-dialog', function(i, element) {

	var content = element.find('.main-media-dialog-content')
	var caption = element.find('.main-media-dialog-caption')

	var onResizeCallback = null

	element.on('click', '.main-media-dialog-close', function() {

		$(document.body).removeClass('main-media-dialog-visible')

		setTimeout(function() {
			element.toggleClass('main-media-dialog-loading', false)
			element.toggleClass('main-media-dialog-playing', false)
			element.toggleClass('main-media-dialog-type-video', false)
			content.empty()
		}, 1000)

	})

	var open = function(path, text) {

		content.empty()
		caption.empty()
		caption.append(text)

		content.css('width', '')
		content.css('height', '')

		element.toggleClass('main-media-dialog-type-video', path.match('player.vimeo.com') != null)
		element.toggleClass('main-media-dialog-loading', true)
		element.toggleClass('main-media-dialog-playing', false)

		setTimeout(function() {

			if (path.match('player.vimeo.com')) {

				var iframe = $('<iframe src="' + path + '" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>')

				content.append(iframe)

				iframe.on('load', function() {
					element.toggleClass('main-media-dialog-loading', false)
					element.toggleClass('main-media-dialog-playing', true)
				})

				onResizeCallback = function() {
					content.css('height', '')
					content.css('width', '')
					var height = iframe.outerHeight()
					content.css('height', iframe.outerHeight() + 'px')
				}

			} else if (path.match(/\.[a-zA-Z0-9]{3,4}$/)) {

				var img = $('<img src="' + path + '" width="100%">')

				content.append(img)

				img.on('load', function() {

					var offset = 0
					var height = img.outerHeight()
					if (height > $(window).height()) {
						offset = $(window).height() - height
						height = $(window).height()
					}

					element.toggleClass('main-media-dialog-loading', false)
					element.toggleClass('main-media-dialog-playing', true)

					content.css('height', height + 'px')
					img.css('transform', 'translateY(' + (offset / 2) + 'px)')
				})

				onResizeCallback = function() {

					var offset = 0
					var height = img.outerHeight()
					if (height > $(window).height()) {
						offset = $(window).height() - height
						height = $(window).height()
					}

					content.css('height', '')
					content.css('height', height + 'px')
					img.css('transform', 'translateY(' + (offset / 2) + 'px)')
				}

			} else {

				var iframe = $('<iframe src="' + path + '" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>')

				content.append(iframe)

				iframe.on('load', function() {
					element.toggleClass('main-media-dialog-loading', false)
					element.toggleClass('main-media-dialog-playing', true)
				})

				onResizeCallback = function() {

					content.css('height', '')
					content.css('width', '')

					var height = iframe.outerHeight()
					if (height > $(window).height()) {
						height = $(window).height()
					}

					content.css('height', height + 'px')
				}
			}

		}, 500)

		$(document.body).addClass('main-media-dialog-visible')
	}

	$(window).on('resize', function() {
		if (onResizeCallback) {
			onResizeCallback();
		}
	})

	//--------------------------------------------------------------------------
	// Pub/Sub
	//--------------------------------------------------------------------------

	$.subscribe('main-media-dialog/open', open)
})

$.attach('a.popup', function(i, element) {

	var href = element.attr('href')
	var title = element.attr('title')

	element.on('click', function(e) {
		e.preventDefault()
		$.publish('main-media-dialog/open', href, title)
	})
})

})(jQuery);