;(function($) {
"use strict"

$.attach('.main-progress-dialog', function(i, element) {

	var message = null
	var caption = element.find('.main-progress-dialog-caption')

	var show = function(text) {
		showCaption(text)
		showDialog()
	}

	var hide = function(text) {
		showCaption(text)
		hideDialog()
	}

	var showDialog = function() {
		$(document.body).addClass('main-progress-dialog-visible')
	}

	var hideDialog = function() {

		setTimeout(function() {
			$(document.body).removeClass('main-progress-dialog-visible')
		}, 1000)

		setTimeout(function() {
			caption.empty()
		}, 1250)
	}

	var showCaption = function(text) {

		if (message) {
			message.removeClass('main-progress-dialog-message-visible')
		}

		message = $('<div class="main-progress-dialog-message"></div>')
		message.html(text)
		caption.append(message)

		requestNextFrame(function() {
			message.addClass('main-progress-dialog-message-visible')
		})

		return this
	}

	//--------------------------------------------------------------------------
	// Pub/Sub
	//--------------------------------------------------------------------------

	$.subscribe('main-progress-dialog/show', show)
	$.subscribe('main-progress-dialog/hide', hide)

})

})(jQuery);