;(function($) {
"use strict"

$.attach('.block-map-viewer', function(i, element) {

	var images = element.find('.block-map-viewer-image:not(:first-child)')
	var layers = element.find('.block-map-viewer-layer:not(:first-child)')

	layers.on('click', function() {
		var index = $(this).index() - 1
		var image = images.eq(index)
		var layer =  layers.eq(index)
		image.toggleClass('block-map-viewer-image-enabled')
		layer.toggleClass('block-map-viewer-layer-enabled')
	})

})

})(jQuery);