(function($) {
"use strict"

$.attach('.block-list-display-item', function(i, element) {

	var href = element.attr('href')
	if (href === '') {
		return
	}

	var title = element.find('.block-list-display-item-title').text()
	var image = element.find('.block-list-display-item-image a').attr('href')

	if (image) element.on('click', '.block-list-display-item-image', function(e) {
		e.preventDefault()
		$.publish('main-media-dialog/open', image, title)
	})

})

})(jQuery);