(function($) {
"use strict"

var open  = false
var count = 0
var currentLabel = null
var currentItems = null
var desktop = false

var cancel = function(e) {
	e.preventDefault()
}

/**
 * Adds data attributes for animation.
 * @since 1.0.0
 */
$.attach('.main-menu', function(i, element) {
	element.find('.primary-nav > .menu-item, .secondary-nav > .menu-item').addClass('menu-item-depth-1').each(function(i, element) {
		$(element).attr('data-menu-item-abs-index', i)
	})
})

/**
 * Mobile menu toggle.
 * @since 1.0.0
 */
$.attach('.main-menu ul.primary-nav > li', function(i, element) {

	element.find('> a').prepend(
		'<span class="menu-item-toggle">' +
			'<span class="menu-item-toggle-line"></span>' +
			'<span class="menu-item-toggle-line"></span>' +
		'</span>'
	)

	if (element.find('ul').length) {
		element.addClass('menu-item-parent')
	}

})

/**
 * Mobile menu accordion.
 * @since 1.0.0
 */
$.attach('.main-menu ul.primary-nav > li > a', function(i, element) {

	element.addClass('primary-nav-link-lvl-1')

	var label = element.closest('li')
	var items = element.closest('li').find(' > ul.sub-menu')

	element.on('click', function() {

		if (currentLabel && currentItems) {

			if (currentLabel.is(label) && currentItems.is(items)) {

				open = false

				label.removeClass('menu-item-open')
				items.removeClass('menu-items-open')
				items.css('height', '')

				count--

				if (count === 0) {
					element.closest('.main-menu').removeClass('main-menu-sub-menu-open')
				}

				currentLabel = null
				currentItems = null

				return
			}

			count--

			currentLabel.removeClass('menu-item-open')
			currentItems.removeClass('menu-items-open')
			currentItems.css('height', '')

			currentLabel = null
			currentItems = null
		}

		var elem = items.get(0)
		if (elem == null)
			return

		var height = elem.scrollHeight
		label.addClass('menu-item-open')
		items.addClass('menu-items-open')
		items.css('height', height)

		currentLabel = label
		currentItems = items

		count++

		if (count === 1) {
			element.closest('.main-menu').addClass('main-menu-sub-menu-open')
		}

		open = true
	})

})

/**
 * Mobile menu show button.
 * @since 1.0.0
 */
$.attach('.main-menu-show-button', function(i, element) {
	element.on('click', function() {
		$.publish('main-menu/show')
	})
})

/**
 * Mobile menu hide button.
 * @since 1.0.0
 */
$.attach('.main-menu-hide-button', function(i, element) {
	element.on('click', function() {
		$.publish('main-menu/hide')
	})
})

/**
 * Opens internal link using the loader.
 * @since 1.0.0
 */
$(document.body).on('click', 'a', function(e) {

	if ($(document.body).hasClass('admin-bar')) {
		return
	}

	var element = $(e.target).closest('a')

	if (element.closest('.main-menu').length == 0 &&
		element.closest('.main-area').length == 0) {
		return
	}

	if (element.closest('.block-open-table').length ||
		element.closest('figure').length) {
		return
	}

	var href = element.attr('href')
	if (href == null) {
		return
	}

	if (href === '' || href.match(/\.[a-zA-Z0-9]{3,4}$/)) {
		return
	}

	var target = element.attr('target')
	if (target && target.toLowerCase() === '_blank') {
		return
	}

	if (href.match(/^http:\/\//i) ||
		href.match(/^https:\/\//i)) {
		var matches = href.match(/^https?\:\/\/([^\/?#]+)(?:[\/?#]|$)/i)
		if (matches) {
			if (location.host !== matches[1]) {
				return
			}
		}
	}

	e.preventDefault()

	if (element.closest('.main-menu').length) {

		if (desktop === false && element.hasClass('primary-nav-link-lvl-1') === false) {
			$.publish('location/set', href)
			$.publish('main-menu/hide')
		}

		return
	}

	$.publish('location/set', href)
	$.publish('main-menu/hide')
})

$.subscribe('main-menu/show', function() {
	$(document.body).toggleClass('main-menu-open', true)
})

$.subscribe('main-menu/hide', function() {

	$(document.body).toggleClass('main-menu-open', false)

	setTimeout(function() {

		open = false
		count = 0
		currentLabel = null
		currentItems = null

		$('.menu-items-open').css('height', '')
		$('.menu-items-open').removeClass('menu-items-open')
		$('.main-menu-sub-menu-open').removeClass('main-menu-sub-menu-open')
		$('.menu-item-open').removeClass('menu-item-open')

	}, 1000)

})

$(window).on('mediaenter', function(e, media) {
	if (media === 'lg') desktop = true
})

$(window).on('medialeave', function(e, media) {
	if (media === 'lg') desktop = false
})

$(window).on('mediaenter', function(e, media) {
	if (media === 'lg') {
		$('.sub-menu').css('height', '')
		$.publish('main-menu/hide')
	}
})

})(jQuery);