(function($) {
"use strict"

$.attach('.block-grid-display', function(i, element) {

	var currentCell = null

	$(document.body).on('click', function() {

		if (currentCell) {
			currentCell.removeClass('block-grid-display-cell-open')
			currentCell = null
		}

	})

	element.find('.block-grid-display-cell').each(function(i, cell) {

		cell = $(cell)

		var tiny = cell.find('.block-grid-display-cell-tiny')
		var full = cell.find('.block-grid-display-cell-full')
		var frame = cell.find('.block-grid-display-cell-frame')
		var inner = cell.find('.block-grid-display-cell-inner')

		tiny.on('click', function(e) {

			e.stopPropagation()

			var windowW = $(window).width()
			var windowH = $(window).height()

			if (currentCell) {
				currentCell.removeClass('block-grid-display-cell-open')
				currentCell = null
			}

			currentCell = cell

			var frameW = parseInt(full.attr('data-frame-width'), 10)
			var frameH = parseInt(full.attr('data-frame-height'), 10)

			full.removeClass('block-grid-display-cell-full-align-right')
			full.removeClass('block-grid-display-cell-full-align-left')

			var offset = full.offset().left + frameW
			if (offset > windowW) {
				full.addClass('block-grid-display-cell-full-align-right')
			} else {
				full.addClass('block-grid-display-cell-full-align-left')
			}

			var paddingT = parseInt(inner.css('padding-top'), 10)
			var paddingB = parseInt(inner.css('padding-bottom'), 10)

			full.css('width', 0)
			full.css('height', 0)

			requestNextFrame(function() {
				cell.addClass('block-grid-display-cell-open')
				full.css('width', frameW)
				full.css('height', frameH)
			})
		})

		var onLoad = function() {
			onResize()
		}

		var onResize = function() {

			cell.find('.block-grid-display-cell-full-image').css('height', tiny.outerHeight())

			full.css('width', '')
			full.css('height', '')
			frame.css('width', '')
			frame.css('height', '')

			var frameW = frame.outerWidth()
			var frameH = frame.outerHeight()

			frame.css('height', frameH)
			frame.css('width', frameW)

			full.css('height', frameH)
			full.css('width', frameW)
			full.attr('data-frame-width', frameW)
			full.attr('data-frame-height', frameH)
		}

		$(window).on('load', onLoad)
		$(window).on('resize', onResize)

		onResize()

	})
})

})(jQuery);