(function($) {
"use strict"

$.attach('.main-header', function(i, element) {

	var small = false

	var content = element.find('.main-header-content')

	var watch = function() {

		var top = $(window).scrollTop()

		if (small === false && top > 500) {
			small = true
			content.addClass('main-header-content-small')
		}

		if (small === true && top <= 500) {
			small = false
			content.removeClass('main-header-content-small')
		}
	}

	$(window).on('mediaenter', function(event, media) {

		if (media === 'lg') {
			$(window).on('scroll', watch)
			$('.menu-primary-menu-container').appendTo('.main-header-primary-menu-content')
			$('.menu-secondary-menu-container').appendTo('.main-header-secondary-menu-content')
		}

	})

	$(window).on('medialeave', function(event, media) {

		if (media === 'lg') {
			$(window).off('scroll', watch)
			$('.menu-primary-menu-container').appendTo('.main-menu-primary-menu')
			$('.menu-secondary-menu-container').appendTo('.main-menu-secondary-menu')
		}

	})

})

})(jQuery);