(function($) {
"use strict"

var desktop = false

$.defineMedia('xs', '(min-width: 480px)')
$.defineMedia('sm', '(min-width: 768px)')
$.defineMedia('md', '(min-width: 992px)')
$.defineMedia('lg', '(min-width: 1200px)')

$(function() {
	FastClick.attach(document.body);
});

$.attach('.scroll-top', function(i, element) {
	element.on('click', function() {
		$('html, body').stop().animate({scrollTop:0}, 1000)
	})
})

$.subscribe('location/change', function(url) {
	$.publish('content/load', url)
})

window.requestNextFrame = function(callback) {
	requestAnimationFrame(function() {
		requestAnimationFrame(function() {
			callback()
		})
	})
}

})(jQuery);