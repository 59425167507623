(function($) {

$.attach('.block-navigation.block-navigation-style-horizontal', function(i, element) {

	element.find('.page_item .page_item').remove()

	var items = element.find('.page_item')
	var count = element.find('.page_item').length
	element.attr('data-navigation-items', count)
	element.attr('data-navigation-items-mod-2', count % 2)
	element.attr('data-navigation-items-mod-3', count % 3)
	element.attr('data-navigation-items-mod-4', count % 4)

	var breakpoint = 'xx'

	var clones = items.clone()

	var locate = function(items, r, c, cols) {

		var item = items.eq(cols * r + c)
		if (item.length) {
			return item.find('a')
		}

		return null
	}

	var update = function(media, resize) {

		var cols = 2

		resize = resize || false

		breakpoint = media

		switch (media) {
			case 'xs':
				cols = 2
				break
			case 'sm':
				cols = 3
				break
			case 'md':
				cols = 4
				break
			case 'lg':
				cols = 4
				break
		}

		if (media === 'xs' || media === 'sm' || media === 'md' || media == 'lg' || resize) {

			var container = element.find('ul')

			var rows = Math.ceil(count / cols)
			var full = Math.floor(count / cols)
			var rest = count % cols

			if (resize == false) {

				container.empty()

				for (var r = 0; r < rows; r++) {

					for (var c = 0; c < cols; c++) {

				     	if (r == rows - 1 && c >= rest && rest != 0) {
				     		continue
				     	}

						if (c < rest) {
							container.append(clones.eq(rows * c + r))
						} else {
				 			container.append(clones.eq(full * c + r + rest))
						}
					}
				}
			}

			items = element.find('.page_item')

			for (var r = 0; r < rows; r++) {

				var max = 0

				for (var c = 0; c < cols; c++) {

					var link = locate(items, r, c, cols)
					if (link == null) {
						continue
					}

					var val = link.css('height', '').outerHeight()
					if (max < val) {
						max = val
					}
				}

				for (var c = 0; c < cols; c++) {

					var link = locate(items, r, c, cols)
					if (link == null) {
						continue
					}

					link.css('height', max)
				}
			}
		}
	}

	$(window).on('mediaenter', function(e, media) {
		switch (media) {
			case 'xs': update('xs'); break
			case 'sm': update('sm'); break
			case 'md': update('md'); break
			case 'lg': update('lg'); break
		}
	})

	$(window).on('medialeave', function(e, media) {
		switch (media) {
			case 'xs': update('xx'); break
			case 'sm': update('xs'); break
			case 'md': update('sm'); break
			case 'lg': update('md'); break
		}
	})

	$(window).on('resize', function() {
		if (breakpoint == 'xs') {
			update('xs', true)
		}
	})

})

})(jQuery);