(function($) {

$.attach('.block-promoted-display', function(i, element) {

	//--------------------------------------------------------------------------
	// Properties
	//--------------------------------------------------------------------------

	var prevButtonElement = null
	var nextButtonElement = null
	var slideElements = null
	var indicatorElements = null
	var index = 0
	var imageToEnter = null
	var imageToLeave = null
	var animating = false

	//--------------------------------------------------------------------------
	// Functions
	//--------------------------------------------------------------------------

	var gotoSlide = function(k, direction) {

		if (animating || index === k)
			return

		animating = true

		var next = k
		var curr = index

		var onImageToEnterAnimationEnd = function(e) {

			if (imageToEnter.is(e.target) === false) {
				return
			}

			index = next
			animating = false
			imageToEnter.off('transitionend', onImageToEnterAnimationEnd)
			imageToEnter.addClass('block-promoted-display-slide-current')
			imageToEnter.removeClass('block-promoted-display-slide-enter-rg')
			imageToEnter.removeClass('block-promoted-display-slide-enter-rg-transition')
			imageToEnter.removeClass('block-promoted-display-slide-enter-lf')
			imageToEnter.removeClass('block-promoted-display-slide-enter-lf-transition')
		}

		var onImageToLeaveAnimationEnd = function(e) {

			if (imageToLeave.is(e.target) === false) {
				return
			}

			imageToLeave.off('transitionend', onImageToLeaveAnimationEnd)
			imageToLeave.removeClass('block-promoted-display-slide-current')
			imageToLeave.removeClass('block-promoted-display-slide-leave-rg')
			imageToLeave.removeClass('block-promoted-display-slide-leave-rg-transition')
			imageToLeave.removeClass('block-promoted-display-slide-leave-lf')
			imageToLeave.removeClass('block-promoted-display-slide-leave-lf-transition')
		}

		imageToEnter = slideElements.eq(next)
		imageToLeave = slideElements.eq(curr)

		if (direction == null) {
			direction = next > curr ? 'next' : 'prev'
		}

		if (direction === 'next') {

			imageToEnter.addClass('block-promoted-display-slide-enter-rg')
			imageToLeave.addClass('block-promoted-display-slide-leave-lf')
			imageToEnter.on('transitionend', onImageToEnterAnimationEnd)
			imageToLeave.on('transitionend', onImageToLeaveAnimationEnd)

			setTimeout(function() {
				imageToEnter.addClass('block-promoted-display-slide-enter-rg-transition')
				imageToLeave.addClass('block-promoted-display-slide-leave-lf-transition')
			}, 50)

			return
		}

		if (direction === 'prev') {

			imageToEnter.addClass('block-promoted-display-slide-enter-lf')
			imageToLeave.addClass('block-promoted-display-slide-leave-rg')
			imageToEnter.on('transitionend', onImageToEnterAnimationEnd)
			imageToLeave.on('transitionend', onImageToLeaveAnimationEnd)

			setTimeout(function() {
				imageToEnter.addClass('block-promoted-display-slide-enter-lf-transition')
				imageToLeave.addClass('block-promoted-display-slide-leave-rg-transition')
			}, 50)

			return
		}
	}

	var gotoNextSlide = function() {
		gotoSlide(index + 1 > slideElements.length - 1 ? 0 : index + 1, 'next')
	}

	var gotoPrevSlide = function() {
		gotoSlide(index - 1 < 0 ? slideElements.length - 1 : index - 1, 'prev')
	}

	var setIndicator = function(index) {
		element.find('.block-promoted-display-dot').removeClass('block-promoted-display-dot-current').eq(index).addClass('block-promoted-display-dot-current')
	}

	//--------------------------------------------------------------------------
	// Events
	//--------------------------------------------------------------------------

	var onPrevButtonClick = function() {
		gotoPrevSlide()
	}

	var onNextButtonClick = function() {
		gotoNextSlide()
	}

	var onIndicatorClick = function(e) {
		gotoSlide($(e.target).index())
	}

	//--------------------------------------------------------------------------
	// Initialization
	//--------------------------------------------------------------------------

	setIndicator(0)

	slideElements = element.find('.block-promoted-display-slide')
	slideElements.eq(0).addClass('block-promoted-display-slide-current')
	prevButtonElement = element.find('.block-promoted-display-prev')
	nextButtonElement = element.find('.block-promoted-display-next')

	element.attr('data-slides', slideElements.length)

	if (slideElements.length <= 1) {
		prevButtonElement.hide()
		nextButtonElement.hide()
	}

	element.on('click', '.block-promoted-display-prev', onPrevButtonClick)
	element.on('click', '.block-promoted-display-next', onNextButtonClick)

	var touch = null
	var touchStartX = 0
	var touchStartY = 0

	element[0].addEventListener('touchcancel', function(e) {

		if (touch) for (var i = 0; i < e.touches.length; i++) {
			var t = e.touches[i]
			if (t.identifier === touch.identifier) {
				touch = null
				break
			}
		}

	})

	element[0].addEventListener('touchstart', function(e) {
		if (touch == null) {
			touch = e.changedTouches[0]
			touchStartX = touch.pageX
			touchStartY = touch.pageY
		}
	})

	element[0].addEventListener('touchmove', function(e) {

		if (touch) for (var i = 0; i < e.touches.length; i++) {

			var t = e.touches[i]
			if (t.identifier === touch.identifier) {

				var diffX = t.pageX - touchStartX
				var diffY = t.pageY - touchStartY

				var absDiffX = Math.abs(diffX)
				var absDiffY = Math.abs(diffY)

				// if (absDiffY > 30) {
				// 	touch = null
				// }

				if (absDiffX > 30) {
					if (absDiffX < 0) gotoPrevSlide()
					if (absDiffY > 0) gotoNextSlide()
					touch = null
				}

				break
			}
		}

	})

	element[0].addEventListener('touchend', function(e) {

		if (touch) for (var i = 0; i < e.touches.length; i++) {
			var t = e.touches[i]
			if (t.identifier === touch.identifier) {
				touch = null
				break
			}
		}

	})

	//--------------------------------------------------------------------------
	// SVG Management
	//--------------------------------------------------------------------------

	var setMaskSize = function(size) {

		slides.each(function(i, slide) {

			var attr = $(slide).attr('data-mask-' + size)
			if (attr == '') {
				return
			}

			$(slide).find('svg').find('image').get(0).setAttribute('mask', 'url(#' + attr + ')')
		})
	}

	var slides = element.find('.block-promoted-display-slide')

	$(window).on('mediaenter', function(e, media) {
		switch (media) {
			case 'xs': setMaskSize('xs'); break
			case 'sm': setMaskSize('sm'); break
			case 'md': setMaskSize('md'); break
			case 'lg': setMaskSize('lg'); break
		}
	})

	$(window).on('medialeave', function(e, media) {
		switch (media) {
			case 'xs': setMaskSize('xx'); break
			case 'sm': setMaskSize('xs'); break
			case 'md': setMaskSize('sm'); break
			case 'lg': setMaskSize('md'); break
		}
	})
})

})(jQuery)