(function($) {
"use strict"

$.attach('.main-loading', function(i, element) {

	element = $(element)

	//--------------------------------------------------------------------------
	// Variables
	//--------------------------------------------------------------------------

	var initialized = false

	var progress = $('.main-loading .progress')

	//--------------------------------------------------------------------------
	// Functions
	//--------------------------------------------------------------------------

	var progressText = document.getElementById('progress-bar-text')

	var canvas  = $('#canvas')
	var canvasW = parseInt(canvas.attr('width'))
	var canvasH = parseInt(canvas.attr('height'))
	var context = canvas.get(0).getContext('2d')

	var bar = new Image()
	var dot = new Image()
	bar.src = canvas.attr('data-bar-image')
	dot.src = canvas.attr('data-dot-image')

	var dotW = 400
	var dotH = 400

	var progress = 0

	var idle = true
	var idleMin = -25
	var idleMax = 125
	var idleVal = -25

	var redrawScheduled = false

	var progressCanvas = document.createElement('canvas')
	progressCanvas.width = canvasW
	progressCanvas.height = canvasH
	var progressContext = progressCanvas.getContext('2d')

	//--------------------------------------------------------------------------
	// Initialization
	//--------------------------------------------------------------------------

	var scheduleRedraw = function() {
		if (redrawScheduled == false) {
			redrawScheduled = true
			requestAnimationFrame(redraw)
		}
	}

	var redraw = function() {

		context.clearRect(0, 0, canvasW, canvasH)

		context.save()
		context.globalAlpha = 0.5
		context.drawImage(bar, 0, 0);
		context.restore()

		if (idle) {

			idleVal += 1.5

			if (idleVal > idleMax) {
				idleVal = idleMin
			}

			var p = idleVal / 100
			var dotX = canvasW * p - dotW / 2
			var dotY = canvasH / 2 - dotH / 2

			progressContext.save()
			progressContext.globalAlpha = 1
			progressContext.drawImage(bar, 0, 0);
			progressContext.globalCompositeOperation = 'source-in'
			progressContext.drawImage(dot, dotX, dotY)
			progressContext.restore()

			context.drawImage(progressCanvas, 0, 0)

		} else {

			var clipW = canvasW * (progress / 100)
			var clipH = canvasH

			context.save()
			context.beginPath()
			context.rect(0, 0, clipW, clipH)
			context.closePath()
			context.clip()
			context.globalAlpha = 0.75
			context.drawImage(bar, 0, 0);
			context.restore()
		}

		redrawScheduled = false

		if (idle) {
			scheduleRedraw(idle)
		}
	}

	//--------------------------------------------------------------------------
	// Initialization
	//--------------------------------------------------------------------------

	var startIdleAnimation = function() {
		idle = true
		scheduleRedraw()
	}

	var pauseIdleAnimation = function() {
		idle = false
		scheduleRedraw()
	}

	var onLoad = function() {
		progressText.innerHTML = '&nbsp;';
		startIdleAnimation()
	}

	var onStart = function() {
		progress = 0
		progressText.innerHTML = '&nbsp;';
		scheduleRedraw()
		pauseIdleAnimation()
	}

	var onProgress = function(value) {
		progress = value
		progressText.innerHTML = Math.round(value) + '%'
		scheduleRedraw()
	}

	var onComplete = function() {

	}

	$.subscribe('content/load', onLoad)
	$.subscribe('loading/start', onStart)
	$.subscribe('loading/progress', onProgress)
	$.subscribe('loading/complete', onComplete)
})

})(jQuery);
