(function ($) {
	"use strict"

	$.attach('.block-contact-form-close', function (i, element) {
		element.on('click', function () {
			$(document.body).removeClass('block-contact-form-visible')
		})
	})

	$.attach('.block-contact-form.block-contact-form-style-embedded', function (i, element) {

	})

	$.attach('.block-contact-form.block-contact-form-style-popup', function (i, element) {
		// This used to be set with CSS, however it crashes MS Edge for some reasons.
		element.css('position', 'fixed')
		$(document.body).append(element)
	})

	$.attach('.block-contact-form div.wpcf7 > form', function (i, element) {
		try {
			element.wpcf7InitForm();
		} catch (e) { }
	})

	$.attach('.block-contact-form form', function (i, element) {
		element.on('submit', function () {
			$.publish('main-progress-dialog/show', 'Sending Form')
		})
	})

	$.attach('.block-contact-form .wpcf7-list-item', function (i, element) {
		element.on('click', '.wpcf7-list-item-label', function () {
			element.find('input').click()
		})
	})

	$.attach('a', function (i, element) {

		var href = element.attr('href')
		if (href == null) {
			return
		}

		var matches = href.match(/#(\d+)/)
		if (matches) {
			var target = $('[data-form=' + matches[1] + ']')
			if (target.length) {
				element.on('click', function () {
					$(document.body).addClass('block-contact-form-visible')
				})
			}
		}

	})

	$(document).on("wpcf7:mailsent", function (e) {
		$.publish('main-progress-dialog/hide', 'Completed')
	})

	$(document).on("wpcf7:invalid", function (e) {
		$.publish('main-progress-dialog/hide', 'Errors were found while processing the form')
	})

	$(function () {

		var wpcf7 = document.querySelector('.wpcf7')

		wpcf7.addEventListener('wpcf7mailsent', function (event) {
			$.publish('main-progress-dialog/hide', 'Completed')
		}, false)

		wpcf7.addEventListener('wpcf7invalid', function (event) {
			$.publish('main-progress-dialog/hide', 'Errors were found while processing the form')
		}, false)

	})

})(jQuery);