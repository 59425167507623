(function($) {

/**
 * Wraps button grid wihin a container.
 */
$.attach('.block-text-area', function(i, element) {

	var buttons = element.find('.button-grid')

	for (var i = 0; i < buttons.length; i++) {

		var button = buttons.eq(i)
		var others = buttons.eq(i).siblings('.button-grid')

		var wrapper = button.closest('.button-grid-group')
		if (wrapper.length === 0) {
			wrapper = $('<div class="button-grid-group"></div>')
			wrapper.insertBefore(button)
			wrapper.append(button, others)
			wrapper.find('.button-grid').wrap('<div class="button-grid-wrap ' + (button.hasClass('button-grid-large') ? 'button-grid-wrap-large' : '') + '"></div>')
		}
	}
})

/**
 * Wraps text cells grid wihin a container.
 */
$.attach('.block-text-area', function(i, element) {

	var cells = element.find('.text-cell')

	for (var i = 0; i < cells.length; i++) {

		var cell   = cells.eq(i)
		var others = cells.eq(i).siblings('.text-cell')

		var wrapper = cell.closest('.text-cell-group')
		if (wrapper.length === 0) {
			wrapper = $('<div class="text-cell-group"></div>')
			wrapper.insertBefore(cell)
			wrapper.append(cell, others)
			wrapper.find('.text-cell').wrap('<div class="text-cell-wrap"></div>')
		}
	}

	var limit = null

	var layout = function() {

		element.find('.text-cell-group').each(function(i, group) {

			group = $(group)

			var height = 0

			var width = group.outerWidth()
			if (width == limit) {
				return
			}

			group.css('height', '')

			limit = width

			var col = 0

			var last = []
			var size = []

			var next = function() {
				last = size
				size = []
				col = 0
			}

			element.find('.text-cell-wrap').each(function(i, wrap) {

				wrap = $(wrap)

				var wrapW = wrap.outerWidth(true)
				var wrapH = wrap.outerHeight(true)

				var x = size[col - 1] && size[col - 1].x || 0
				var y = last[col - 0] && last[col - 0].y || 0

				if (x + wrapW > limit + 1) {
					next()
					x = size[col - 1] && size[col - 1].x || 0
					y = last[col - 0] && last[col - 0].y || 0
				}

				wrap.css('top', y + 'px')
				wrap.css('left', x + 'px')

				size[col] = {
					x: x + wrapW,
					y: y + wrapH
				}

				col = col + 1

				if (height < y + wrapH) {
					height = y + wrapH
				}

			})

			group.css('height', height)

		})

	}

	$(window).on('load', function() {
		layout()
	})

	$(window).on('resize', function() {
		layout()
	})

	layout()
})

/**
 * Embeds the figcaption within the text.
 */
$.attach('.block-text-area img.embed-caption', function(i, element) {

	var f = element.closest('figure')
	var a = element.closest('figure').find('a')

	f.addClass('embed-caption').find('figcaption').appendTo(a)

	var path = a.attr('href')
	var text = f.find('figcaption').text()

	if (path.match('player.vimeo.com')) {
		f.addClass('type-video')
	}

	var target = a.attr('target')
	if (target == null || target.toLowerCase() !== '_blank') {
		a.on('click', function(e) {
			e.preventDefault()
			$.publish('main-media-dialog/open', path, text)
		})
	}

})

/**
 * Fixes video
 */

})(jQuery);