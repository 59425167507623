(function($) {
"use strict"

$.attach('.paged', function(i, element) {

	element.on('detach', function() {

		if (scroller) {
			scroller.destroy()
		}

		$(window).off('load', onLoad)
		$(window).off('resize', onResize)
		$(window).off('mediaenter', onMediaEnter)
		$(window).off('medialeave', onMediaLeave)
		$(window).off('touchstart', onTouchCancel)
		$(window).off('touchstart', onTouchStart)
		$(window).off('touchmove', onTouchMove)
		$(window).off('touchend', onTouchEnd)
	})

	//--------------------------------------------------------------------------
	// Variables
	//--------------------------------------------------------------------------

	var touch = null
	var lockd = false
	var touchStartX = null
	var touchStartY = null

	var pages = element.find('.paged-page')
	var frame = element.find('.paged-frame')
	var inner = element.find('.paged-inner')
	var outer = element

	var indicators = element.find('.page-indicator-dot')

	var scroller = new IScroll(frame[0], {
		mouseWheel: false,
    	scrollbars: false,
    	scrollX: true,
    	scrollY: true,
    	snap: true,
    	snapSpeed: 350,
    	snapThreshold: 0.3,
    	bounce: false,
    	momentum: false,
    	bounceEasing: 'quadratic',
    	preventDefault: false,
    	probeType: 2
	})

	$.subscribe('content/dismiss', function() {
		scroller.destroy()
	})

	//--------------------------------------------------------------------------
	// Functions
	//--------------------------------------------------------------------------

	var onLoad = function() {
		onResize()
		onScrollerScrollEnd()
		scroller.goToPage(1, 0, 0)
	}

	var onResize = function() {

		var size = frame.outerWidth()
		pages.css('width', size)
		inner.css('width', size * pages.length)
		scroller.refresh()

		if (outer.outerWidth() > inner.outerWidth()) {
			scroller.goToPage(1, 0, 0)
			scroller.disable()
		} else {
			scroller.enable()
		}

	}

	var getTouch = function(e) {

		if (touch) {
			var touches = e.originalEvent.touches
			if (touches) {
				for (var i = 0; i < touches.length; i++) {
					if (touches[i].identifier === touch.identifier) {
						return touches[i]
					}
				}
			}
		}

		return null
	}

	var onTouchCancel = function(e) {
		var t = getTouch(e)
		if (t == null) {
			touch = null
			lockd = false
		}
	}

	var onTouchStart = function(e) {
		if (touch == null) {
			touch = e.originalEvent.changedTouches[0]
			touchStartX = scroller.x
			touchStartY = scroller.y
		}
	}

	var onTouchMove = function(e) {
		if (lockd) e.preventDefault()
	}

	var onTouchEnd = function(e) {
		var t = getTouch(e)
		if (t == null) {
			touch = null
			lockd = false
		}
	}

	var onMediaEnter = function(e, m) {
		setTimeout(function() {
			onResize()
			scroller.goToPage(1, 0, 0)
		}, 32)
	}

	var onMediaLeave = function(e, m) {
		setTimeout(function() {
			onResize()
			scroller.goToPage(1, 0, 0)
		}, 32)
	}

	var onScrollerScroll = function() {
		if (lockd === false) {
			if (Math.abs(touchStartX - scroller.x) > 15 ||
				Math.abs(touchStartY - scroller.y) > 15) {
				lockd = true
			}
		}
	}

	var onScrollerScrollEnd = function() {
		indicators.removeClass('page-indicator-dot-current').eq(scroller.currentPage.pageX).addClass('page-indicator-dot-current')
	}

	$(window).on('load', onLoad)
	$(window).on('resize', onResize)
	$(window).on('mediaenter', onMediaEnter)
	$(window).on('medialeave', onMediaLeave)
	$(window).on('touchstart', onTouchCancel)
	$(window).on('touchstart', onTouchStart)
	$(window).on('touchmove', onTouchMove)
	$(window).on('touchend', onTouchEnd)

	scroller.on('scroll', onScrollerScroll)
	scroller.on('scrollEnd', onScrollerScrollEnd)

	indicators.on('click', function(e) {
		scroller.goToPage($(e.target).index(), 0, 250)
	})

})

})(jQuery);