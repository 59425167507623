(function($) {
"use strict"

$.attach('.block-carousel', function(i, element) {

	//--------------------------------------------------------------------------
	// Properties
	//--------------------------------------------------------------------------

	/**
	 * Control to show the previous carousel item.
	 * @var prevButtonElement
	 * @since 1.0.0
	 */
	var prevButtonElement = null

	/**
	 * Control to show the next carousel item.
	 * @var nextButtonElement
	 * @since 1.0.0
	 */
	var nextButtonElement = null

	/**
	 * Images contained in the carousel.
	 * @var slideElements
	 * @since 1.0.0
	 */
	var slideElements = null

	/**
	 * Controls to indicate/change carousel status.
	 * @var indicatorElements
	 * @since 1.0.0
	 */
	var indicatorElements = null

	/**
	 * The carousel's index.
	 * @var index
	 * @since 1.0.0
	 */
	var index = 0

	/**
	 * The image being animated to enter.
	 * @var imageToEnter
	 * @since 1.0.0
	 */
	var imageToEnter = null

	/**
	 * The image being animated to leave.
	 * @var imageToLeave
	 * @since 1.0.0
	 */
	var imageToLeave = null

	/**
	 * Whether the carousel is in the process of animating or not.
	 * @var animating
	 * @since 1.0.0
	 */
	var animating = false

	//--------------------------------------------------------------------------
	// Functions
	//--------------------------------------------------------------------------

	/**
	 * Animates to a specified slide.
	 * @var gotoSlide
	 * @since 1.0.0
	 */
	var gotoSlide = function(k, direction) {

		if (animating || index === k)
			return

		animating = true

		var next = k
		var curr = index

		var onImageToEnterAnimationEnd = function(e) {

			if (imageToEnter.is(e.target) === false) {
				return
			}

			index = next
			animating = false
			imageToEnter.off('transitionend', onImageToEnterAnimationEnd)
			imageToEnter.addClass('block-carousel-slide-current')
			imageToEnter.removeClass('block-carousel-slide-enter-rg')
			imageToEnter.removeClass('block-carousel-slide-enter-rg-transition')
			imageToEnter.removeClass('block-carousel-slide-enter-lf')
			imageToEnter.removeClass('block-carousel-slide-enter-lf-transition')
		}

		var onImageToLeaveAnimationEnd = function(e) {

			if (imageToLeave.is(e.target) === false) {
				return
			}

			imageToLeave.off('transitionend', onImageToLeaveAnimationEnd)
			imageToLeave.removeClass('block-carousel-slide-current')
			imageToLeave.removeClass('block-carousel-slide-leave-rg')
			imageToLeave.removeClass('block-carousel-slide-leave-rg-transition')
			imageToLeave.removeClass('block-carousel-slide-leave-lf')
			imageToLeave.removeClass('block-carousel-slide-leave-lf-transition')
		}

		imageToEnter = slideElements.eq(next)
		imageToLeave = slideElements.eq(curr)

		if (direction == null) {
			direction = next > curr ? 'next' : 'prev'
		}

		if (direction === 'next') {

			imageToEnter.addClass('block-carousel-slide-enter-rg')
			imageToLeave.addClass('block-carousel-slide-leave-lf')
			imageToEnter.on('transitionend', onImageToEnterAnimationEnd)
			imageToLeave.on('transitionend', onImageToLeaveAnimationEnd)

			setTimeout(function() {
				imageToEnter.addClass('block-carousel-slide-enter-rg-transition')
				imageToLeave.addClass('block-carousel-slide-leave-lf-transition')
			}, 50)

			return
		}

		if (direction === 'prev') {

			imageToEnter.addClass('block-carousel-slide-enter-lf')
			imageToLeave.addClass('block-carousel-slide-leave-rg')
			imageToEnter.on('transitionend', onImageToEnterAnimationEnd)
			imageToLeave.on('transitionend', onImageToLeaveAnimationEnd)

			setTimeout(function() {
				imageToEnter.addClass('block-carousel-slide-enter-lf-transition')
				imageToLeave.addClass('block-carousel-slide-leave-rg-transition')
			}, 50)

			return
		}
	}

	/**
	 * Animates to the next slide.
	 * @var gotoNextSlide
	 * @since 1.0.0
	 */
	var gotoNextSlide = function() {
		gotoSlide(index + 1 > slideElements.length - 1 ? 0 : index + 1, 'next')
	}

	/**
	 * Animates to the previous slide.
	 * @var gotoPrevSlide
	 * @since 1.0.0
	 */
	var gotoPrevSlide = function() {
		gotoSlide(index - 1 < 0 ? slideElements.length - 1 : index - 1, 'prev')
	}

	//--------------------------------------------------------------------------
	// Events
	//--------------------------------------------------------------------------

	/**
	 * Called when the previous button is clicked.
	 * @var onPrevButtonClick
	 * @since 1.0.0
	 */
	var onPrevButtonClick = function() {
		gotoPrevSlide()
	}

	/**
	 * Called when the next button is clicked.
	 * @var onNextButtonClick
	 * @since 1.0.0
	 */
	var onNextButtonClick = function() {
		gotoNextSlide()
	}

	/**
	 * Called when a specified indicator is clicked.
	 * @var onIndicatorClick
	 * @since 1.0.0
	 */
	var onIndicatorClick = function(e) {
		gotoSlide($(e.target).index())
	}

	//--------------------------------------------------------------------------
	// Initialization
	//--------------------------------------------------------------------------

	slideElements = element.find('.block-carousel-slide')

	slideElements.eq(0).addClass('block-carousel-slide-current')

	prevButtonElement = element.find('.block-carousel-prev')
	nextButtonElement = element.find('.block-carousel-next')

	if (slideElements.length <= 1) {
		//prevButtonElement.hide()
		//nextButtonElement.hide()
	}

	element.on('click', '.block-carousel-prev', onPrevButtonClick)
	element.on('click', '.block-carousel-next', onNextButtonClick)

	if (element.closest('.fit-screen').length) {

		var first = element.is(':first-child')

		var onResize = function() {

			if (first) {
				element.css('height', $(window).height() - element.offset().top)
			} else {
				element.css('height', $(window).height())
			}

		}

		$(window).on('resize', onResize)

		onResize()
	}
})

})(jQuery);
