(function($) {
"use strict"

$.attach('.main-audio', function(i, element) {

	var audio = element.get(0)

	audio.addEventListener('canplaythrough', function() {
		$('html').addClass('main-audio-can-play')
	})

	$('.mute-button').on('click', function() {
		audio.muted = !audio.muted
		// 2 mute button
		$('.mute-button').toggleClass('mute-button-muted', audio.muted)
	})

	var home = function() {
		return $(document.body).hasClass('home')
	}

	var fade = function(volume) {
		$(audio).animate({volume:volume}, 1000)
	}

	audio.volume = 0

	$(window).on('load', function() {
		if (home()) {
			fade(1)
			audio.play()
		}
	})

	$.subscribe('loading/start', function() {
		fade(home() ? 1 : 0)
	})

})

})(jQuery);