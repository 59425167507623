;(function($) {
"use strict"

$.attach('.page-header', function(i, element) {

	//--------------------------------------------------------------------------
	// Properties
	//--------------------------------------------------------------------------

	var prevButtonElement = null
	var nextButtonElement = null
	var slideElements = null
	var indicatorElements = null

	var index = 0
	var imageToEnter = null
	var imageToLeave = null
	var animating = false
	var safeTimeout = null;
	var playTimeout = null

	//--------------------------------------------------------------------------
	// Functions
	//--------------------------------------------------------------------------

	var gotoSlide = function(k, direction, force) {

		if ((animating/* && !force*/) || index === k) {
			return
		}

		animating = true

		var next = k
		var curr = index

		element.find('.page-header-dot')
			.removeClass('page-header-dot-current')
			.eq(next)
			.addClass('page-header-dot-current')

		stop()

		var safe = function() {

			index = next
			animating = false

			imageToEnter.off('transitionend', onImageToEnterAnimationEnd)
			imageToEnter.addClass('page-header-slide-current')
			imageToEnter.removeClass('page-header-slide-enter-rg')
			imageToEnter.removeClass('page-header-slide-enter-rg-transition')
			imageToEnter.removeClass('page-header-slide-enter-lf')
			imageToEnter.removeClass('page-header-slide-enter-lf-transition')

			imageToLeave.off('transitionend', onImageToLeaveAnimationEnd)
			imageToLeave.removeClass('page-header-slide-current')
			imageToLeave.removeClass('page-header-slide-leave-rg')
			imageToLeave.removeClass('page-header-slide-leave-rg-transition')
			imageToLeave.removeClass('page-header-slide-leave-lf')
			imageToLeave.removeClass('page-header-slide-leave-lf-transition')

			stop()
			play()
		}

		var onImageToEnterAnimationEnd = function(e) {

			if (imageToEnter.is(e.target) === false) {
				return
			}

			index = next
			animating = false
			imageToEnter.off('transitionend', onImageToEnterAnimationEnd)
			imageToEnter.addClass('page-header-slide-current')
			imageToEnter.removeClass('page-header-slide-enter-rg')
			imageToEnter.removeClass('page-header-slide-enter-rg-transition')
			imageToEnter.removeClass('page-header-slide-enter-lf')
			imageToEnter.removeClass('page-header-slide-enter-lf-transition')

			safeTimeout = clearTimeout(safeTimeout)

			stop()
			play()
		}

		var onImageToLeaveAnimationEnd = function(e) {

			if (imageToLeave.is(e.target) === false) {
				return
			}

			imageToLeave.off('transitionend', onImageToLeaveAnimationEnd)
			imageToLeave.removeClass('page-header-slide-current')
			imageToLeave.removeClass('page-header-slide-leave-rg')
			imageToLeave.removeClass('page-header-slide-leave-rg-transition')
			imageToLeave.removeClass('page-header-slide-leave-lf')
			imageToLeave.removeClass('page-header-slide-leave-lf-transition')
		}

		imageToEnter = slideElements.eq(next)
		imageToLeave = slideElements.eq(curr)

		if (direction == null) {
			direction = next > curr ? 'next' : 'prev'
		}

		if (direction === 'next') {

			imageToEnter.addClass('page-header-slide-enter-rg')
			imageToLeave.addClass('page-header-slide-leave-lf')
			imageToEnter.on('transitionend', onImageToEnterAnimationEnd)
			imageToLeave.on('transitionend', onImageToLeaveAnimationEnd)

			setTimeout(function() {
				imageToEnter.addClass('page-header-slide-enter-rg-transition')
				imageToLeave.addClass('page-header-slide-leave-lf-transition')
			}, 50)

			safeTimeout = setTimeout(safeTimeout, 3000)

			return
		}

		if (direction === 'prev') {

			imageToEnter.addClass('page-header-slide-enter-lf')
			imageToLeave.addClass('page-header-slide-leave-rg')
			imageToEnter.on('transitionend', onImageToEnterAnimationEnd)
			imageToLeave.on('transitionend', onImageToLeaveAnimationEnd)

			setTimeout(function() {
				imageToEnter.addClass('page-header-slide-enter-lf-transition')
				imageToLeave.addClass('page-header-slide-leave-rg-transition')
			}, 50)

			safeTimeout = setTimeout(safeTimeout, 3000)

			return
		}
	}

	var gotoNextSlide = function(force) {
		gotoSlide(index + 1 > slideElements.length - 1 ? 0 : index + 1, 'next', force)
	}

	var gotoPrevSlide = function(force) {
		gotoSlide(index - 1 < 0 ? slideElements.length - 1 : index - 1, 'prev', force)
	}

	var setIndicator = function(index) {
		element.find('.page-header-dot').removeClass('page-header-dot-current').eq(index).addClass('page-header-dot-current')
	}

	var stop = function() {
		playTimeout = clearTimeout(playTimeout)
	}

	var play = function() {
		playTimeout = setTimeout(gotoNextSlide, 6000)
	}

	//--------------------------------------------------------------------------
	// Events
	//--------------------------------------------------------------------------

	var onPrevButtonClick = function() {
		gotoPrevSlide()
	}

	var onNextButtonClick = function() {
		gotoNextSlide()
	}

	var onIndicatorClick = function(e) {
		gotoSlide($(e.target).index())
	}

	//--------------------------------------------------------------------------
	// Initialization
	//--------------------------------------------------------------------------

	setIndicator(0)

	slideElements = element.find('.page-header-slide')
	slideElements.eq(0).addClass('page-header-slide-current')
	prevButtonElement = element.find('.page-header-prev')
	nextButtonElement = element.find('.page-header-next')

	element.attr('data-slides', slideElements.length)

	if (slideElements.length <= 1) {
		prevButtonElement.hide()
		nextButtonElement.hide()
		element.find('.page-header-dots').hide()
	} else {
		setTimeout(play, 2000)
	}

	element.on('click', '.page-header-prev', onPrevButtonClick)
	element.on('click', '.page-header-next', onNextButtonClick)

	var touch = null
	var touchStartX = 0
	var touchStartY = 0

	element[0].addEventListener('touchcancel', function(e) {

		if (touch) for (var i = 0; i < e.touches.length; i++) {
			var t = e.touches[i]
			if (t.identifier === touch.identifier) {
				touch = null
				break
			}
		}

	})

	element[0].addEventListener('touchstart', function(e) {
		if (touch == null) {
			touch = e.changedTouches[0]
			touchStartX = touch.pageX
			touchStartY = touch.pageY
		}
	})

	element[0].addEventListener('touchmove', function(e) {

		if (touch) for (var i = 0; i < e.touches.length; i++) {

			var t = e.touches[i]
			if (t.identifier === touch.identifier) {

				var diffX = t.pageX - touchStartX
				var diffY = t.pageY - touchStartY

				var absDiffX = Math.abs(diffX)
				var absDiffY = Math.abs(diffY)

				/*
				if (absDiffY > 30) {
					touch = null
				}
				*/

				if (absDiffX > 30) {
					if (absDiffX < 0) gotoPrevSlide(true)
					if (absDiffY > 0) gotoNextSlide(true)
					touch = null
				}

				break
			}
		}

	})

	element[0].addEventListener('touchend', function(e) {

		if (touch) for (var i = 0; i < e.changedTouches.length; i++) {
			var t = e.changedTouches[i]
			if (t.identifier === touch.identifier) {
				touch = null
				break
			}
		}

	})

	//--------------------------------------------------------------------------
	// Pub/Sub
	//--------------------------------------------------------------------------

	/**
	 * Goto a specific slide.
	 * @subscribe carousel/goto
	 * @since 1.0.0
	 */
	$.subscribe('carousel/goto', gotoSlide)

	/**
	 * Goto the next slide.
	 * @subscribe carousel/next
	 * @since 1.0.0
	 */
	$.subscribe('carousel/next', gotoNextSlide)

	/**
	 * Goto the prev slide.
	 * @subscribe carousel/prev
	 * @since 1.0.0
	 */
	$.subscribe('carousel/prev', gotoPrevSlide)
})

$.attach('.page-header-video', function(i, element) {

	element.find('a').on('click', function(e) {

		e.preventDefault()

		var title = $(this).attr('data-title')
		var video = $(this).attr('data-video')

		$.publish('main-media-dialog/open', video, title)
	})

});

})(jQuery);