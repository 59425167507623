(function($) {
"use strict"

	$.attach('.primary-nav a', function(i, link) {

		link = $(link)

		var href = link.attr('href')
		if (href == null) {
			return
		}

		link.closest('.menu-item').find('.sub-menu a[href="' + href + '"]').each(function(i, link) {
			$(link).closest('.menu-item').addClass('menu-item-introduction')
		})

	})

})(jQuery);